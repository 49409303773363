.welcomePage {
    position: relative;
    width:100svw;
    background: #121212;
    overflow-x:hidden;
    /* Disable text selection for everything */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
}

.heroElement {
    width: 100svw;
    max-width: 100%;
    word-wrap: break-word;
    height: auto;
    text-align: left;
    box-sizing: border-box;
    display: inline-block;
    color: white;
    background: transparent;
    /* if we can clip, do it */
    font-size: 1.1em;
    /* Disable text select */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    margin: 0;
    transition: transform .25s ease-in-out;
    padding-top:1%;
    text-align: right;
    line-height: 1;
    position:relative;
    padding-bottom:0;
    overflow:hidden;
    background:transparent;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height:80svh;
}

.heroElement:before,
.heroElement:after {
    position: absolute;
    content: '';
}

.heroElement:before {
    background-image: inherit;
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
}

.demoVideo{
    position:relative;
    height:auto;
    max-height:500px;
    text-align:right;
    display:inline-block;
    border-radius:15px;
    z-index:20;
}
.heroText{
    height:auto;
    z-index:50;
    text-align:center;
    padding-left:5%;
    padding-right:5%;
    font-size:3.5vw;
    padding-top:2%;
}
.heroText h1{
    font-size:5em;
    margin:0;
    padding:0;
    font-weight:300;
    border-bottom:1px solid white;
}
.heroText h2{
    font-size:3em;
    font-weight:100;
    margin-top:0;
    margin-bottom:0;
    padding-bottom:0;
    padding-top:0;
    text-align: center;
}
.heroBackgroundVideo{
    position:absolute;
    top:0;
    right:0;
    z-index:1;
}
.heroElementGradient{
    /* A gradient from #121212 to transparent from left to right */
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(18,18,18,1) 45%, rgba(18,18,18,1) 100%);
    position:absolute;
    top:0;
    left:0;
    z-index:2;
    width:100%;
    height:100%;
    display:inline-block;
}
.welcomeBackBGVideo {
    width: 90%;
    height: auto;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
}

.aboutSection {
    color: white;
    box-sizing: border-box;
    padding: 6%;
    text-align: center;
    padding-bottom:5vh;
    background:#121212;
    padding-top:1%;
}

.aboutSection h1 {
    margin: 0;
    margin-top:15px;
    text-align: left;
    font-weight:500;
}
.aboutSection h2{
    margin: 0;
    margin-top:15px;
    text-align: center;
    font-weight:500;
}
.aboutSection h3{
    font-weight: 400;
    margin-bottom:5% !important;
}
.aboutSection h3, .aboutSection ul, .aboutSection li, .aboutSection p, .aboutSection h2{
    margin:0;
    margin-top:15px;
    padding-right:1%;
}

.featuresWrapper {
    display: inline-block;
    width: 100%;
}

.featureSectionHeader {
    /* border-bottom:2px solid white; */
    font-size: 2em;
    text-align: left;
    width:100%;
    border-bottom:2px solid;
    margin:auto;
}

.premiumFeature {
    display: inline-block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90vw;
    width: 100%;
    height: 250px;
    border: 2px solid white;
    border-radius: 15px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    transition: box-shadow .2s ease-in-out, transform .1s ease-in-out;
    margin: 0%;
    cursor: pointer;
    margin-bottom:5%;
}

.premiumFeature:active {
    transform: scale(0.99) !important;
}

.premiumFeature>h3 {
    margin-top: 0;
    width: 100%;
    background: white;
    text-align: center;
    color: #333;
    font-weight: bold;
    font-size: 1.3em;
    padding: 5px;
    box-sizing: border-box;
}

.premiumFeature>p {
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s ease-in-out;
    font-size: 1.2em;
    padding: 0;
    margin: 0;
    width: 90%;
    margin:auto;
    opacity: 1;
    text-align: center;
    vertical-align:middle;
    height:100%;
}

.premiumFeature:hover>img {
    opacity: 0;
}

.premiumFeature>img {
    width: auto;
    height: 50%;
    filter: brightness(0) invert(1);
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .5s ease-in-out;
    text-align: center;
    margin: auto;
    opacity:0;
}
@media screen and (min-height: 500px){
    .heroElement{
        height:50%;
    }
    .premiumFeature:hover{
        transform: scale(1.01);
    }
}
/* Add media queries for desktop mode */
@media screen and (min-width: 600px) and (orientation: landscape) {
    .heroElementGradient{
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(18,18,18,1) 35%, rgba(18,18,18,1) 100%);
    }
    .featureSectionHeader{
        text-align: left !important;
    }
    .premiumFeature {
        width: 45%;
        margin: 2.5%;
    }
    .heroText{
        font-size:1vw;
        padding-top:0;
    }
    .aboutSection h1{
        text-align: left;
    }
    .aboutSection h3{
        font-size:2em;
    }
    .premiumFeature:hover {
        box-shadow:
            inset 0 0 30px whitesmoke,
            inset 20px 0 40px #f0f,
            inset -20px 0 40px #0ff,
            inset 20px 0 150px #f0f,
            inset -20px 0 150px #0ff,
            0 0 25px #fff,
            -10px 0 40px #f0f,
            10px 0 40px #0ff;
        transform: scale(1.05);
    }
    .demoVideo{
        max-height:450px;
    }
    .featureSectionDescription{
        text-align: left;
    }
}