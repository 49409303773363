
html{
  overflow-x:hidden;
  overflow-y:overlay;
  text-transform:capitalize;
  background: #121212;
  /* background:#252E3D; */
  color:white;
  padding:0;
  margin:0;
  width:100vw;
}
body{
  overflow-x:hidden;
  overflow-y:inherit;
  padding:0;
  margin:0;
  padding-top:env(safe-area-inset-top, 20vh) !important;
  padding-bottom:env(safe-area-inset-bottom, 20vh) !important;
  position:relative;
  background: #121212;
}
#root{
  position:relative;
  overflow-x:hidden;
}
.CenteredWrapper {
  display: grid;
  height: 100vh;
  width: 100vw;
  place-items: center;
  position: relative;
  background: #121212;
  text-align: center;
  padding: 4%;
  box-sizing: border-box;
}
.CenteredWrapper h3 {
  font-weight: 200;
}
.ScrollableWrapper{
  text-align: center;
  width:100svw;
  position:relative;
  font-family:Poppins;
  box-sizing: border-box;
  max-height:100svh;
  height:100svh;
  text-align: center;
}
.ScrollableWrapper h2{
  font-weight: 200;
}
.ScrollableWrapper h3{
  font-weight: 200;
}
.AuthWrapper{
  width:100%;
}
/* Scroll bar styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #181926;
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #474A71;
  border-radius:1em;
}

::-webkit-scrollbar-thumb:hover {
  background: #3A1C71;
}

@font-face{
  font-family: 'OpenSans';
  src: local('OpenSans'), url('./res/OpenSans.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./res/fonts/Poppins-Medium.ttf') format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
.Navbar{
  position:fixed;
  top:0;
  right:0;
  max-width:50px;
  width:15vw;
  height:100vh;
  background-color: rgba(0,0,0,0);
  color:white;
  z-index: 100;
  font-family:OpenSans;
  overflow:visible;
  box-sizing: border-box;
}
.NavbarLink{
  position:relative;
  font-size: 30px;
  font-weight: 400;
  width:auto;
  text-align: center;
  text-decoration:none;
  color:inherit;
  padding-top: 5px;
  box-sizing: content-box;
  transition:border 2s ease-in-out, width 2s ease-in-out, 2s ease-in-out, color 2s ease-in-out, 0.2s ease-in-out, padding 0.2s ease-in-out;
}
.LoginIcon{
  max-width:100%;
  height:auto;
  width:auto;
  /* filter:brightness(0) invert(1); */
  background:white;
  border-radius:50%;
  box-sizing: content-box;
  overflow:visible;
  padding:10px;
  transition:box-shadow 0.5s ease-in-out, filter 0.5s ease-in-out, transform 0.5s ease-in-out, 0.2s ease-in-out;
  right:50%;
  position:absolute;
  bottom:2%;
}
.LoginIcon:hover{
  box-shadow:
        0 0 25px #fff,
        -10px 0 40px #f0f,
        10px 0 40px #0ff;
        width:150px !important;
}
.LoginIcon:hover ~ .SoloNavbarLink:after{
  content:"Login";
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  color:black;
  font-size:1.5em;
  font-weight:bold;
  text-align:center;
  width:100%;
  height:100%;
  opacity:1;
  transition:opacity 0.5s ease-in-out;
}
.SoloNavbarLink{
  position: absolute;
  bottom:1%;
  left:50%;
  transform:translateX(-50%);
  text-align: center;
  padding:0;
  margin:0;
}
#ThemesLink{
  text-align: right;
  padding-right:2%;
  padding-left: 2%;
  opacity:0;
  animation: LinkFadeIn 2s 2s forwards;
  width:auto;
}
#AccountLink{
  padding-left:2%;
  padding-right: 2%;
  animation: LinkFadeIn 2s 2s forwards;
  width:auto;
}
.MenuIcon{
  max-width:30px;
  position:fixed;
  bottom:calc(5% + env(safe-area-inset-top));
  right:0;
  transform:translateX(-5vw);
  z-index:100;
  cursor: pointer;
  background:white;
  border-radius:50%;
  box-sizing: content-box;
  overflow:visible;
  padding:10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  transition:box-shadow 0.5s ease-in-out, filter 0.5s ease-in-out, transform 0.5s ease-in-out;
  margin-bottom:env(safe-area-inset-bottom, 20vh) !important;
  display:flex;
  justify-content:center;
  align-items:center;
}
.MenuIcon img{
  max-width:30px;
  transition:transform .4s ease-in-out, filter .4s ease-in-out;
}
.MenuActive{
  /* background:#121212; */
  transform:translateX(-80vw);
}
.MenuActive img{
  /* Rotate 90 degrees */
  transform:rotate(180deg);
  /* filter:invert(); */
}
.PopoutMenu{
  width:70vw;
  position:fixed;
  z-index:-1;
  right:0;
  top:0;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  transform:translate(100%,0);
  transition:transform 0.5s ease-in-out;
  background:#1F1F1F;
  background: #1A2631;
  color:black;
  font-weight: bold;
  min-width:50svw;
  box-sizing: border-box;
  text-align: center;
  /* padding-bottom:env(safe-area-inset-bottom, 0) !important; */
  box-sizing: border-box;
  height:100svh;
  border-bottom-left-radius: 1em;
  border-top-left-radius: 1em;
  overflow:hidden;
  /* drop shadow on left side of div */
  box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.25);
}
.PopoutMenu h1{
  color:black;
  font-weight:350;
  width:100%;
  height:auto;
  background:white;
  margin:0;
  padding:1%;
}
.PopoutMenu h2{
  position:absolute;
  top:10%;

}
.PopoutMenu h2, .PopoutMenu h3{
  color:white;
  font-weight: 200;
}
.PopoutMenuButtonsWrapper{
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center;
  height:40%;
  width:100%;
  padding:5%;
  box-sizing: border-box;
  margin-bottom:env(safe-area-inset-bottom, 50vh) !important;
}
.ShowMenu{
  transform:translate(0, 0);
}
.ShowMenu ~ #HomeLink{
  color:white !important;
}
.PopoutLink{
  color:white;
  font-size: 30px;
  font-weight: 400;
  width:auto;
  text-align: center;
  height:auto;
  padding:10%;
  padding-top: 5%;
  padding-bottom:5%;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 15px;
  width:100%;
  background:rgba(250,250,250,.1);
  display:flex;
  justify-content:center;
  align-items:center;
  transition:background-color .2s ease-in-out, opacity .5s ease-in-out;
}
.PopoutLink:active{
  background:rgba(250,250,250,.2);
}
.PopoutLink.HiddenPopoutLink{
  opacity:0;
  pointer-events: none;
}
.PopoutLink.ActiveLink{
  border:white 2px solid;
}
.BackgroundImage{
  width:100vw;
  height:100vh;
  position:absolute;
  background:white;
  overflow:hidden;
}


@keyframes LinkFadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.LoginStatus{
  font-size: 20px;
  font-weight: 300;
  display:flex;
  justify-content:space-evenly;
  align-items:center;
  flex-direction: row;
  width:100%;
}

.AccountControlButton{
  /* Remove default button stylings */
  background-color:transparent;
  border:none;
  outline:none;
  cursor:pointer;
  /* Remove default link stylings */
  text-decoration:none;
  color:inherit;
  /* Add custom stylings */
  font-size: 20px;
  font-weight: 600;
  font-family:inherit;
  padding-right:1%;
  text-align: right;
  width:100%;
}



.TypeAnimation{
  font-size:40px;
  font-weight:700;
  /* Center it within its parent div */
  position:fixed;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  animation: TypeFadeOut 2s 12s forwards;
}

@keyframes TypeFadeOut{
  0%{
    opacity:1;
  }
  100%{
    opacity:0;
  }
}

/* Login Page */
.LoginPageWrapper{
  width:100vw;
  place-items:center;
  position:relative;
  background:#121212;
  overflow-x:hidden;
  overflow-y:scroll;
  margin:0;
}
.LoginModal{
  /* Center vertically and horizontally */
  /* position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%); */
  /* Add custom stylings */
  max-width:100%;
  width:100%;
  margin:0;
  padding:0;
  padding-top:5vh;
  padding-bottom:5vh;
  text-align: center;
  font-weight:50 !important;
  align-items:center;
  transition:opacity 0.2s ease-in-out;
  box-sizing: border-box;
}
.HiddenModal{
  opacity:0 !important;
}
.InputDiv{
  text-align: center;
  position:relative;
  z-index: 100;
  width:auto;
  max-width:70%;
  height:52px;
  margin:auto;
  margin-bottom:5%;
}
.LoginInput{
  position:relative;
  height:100%;
  width:100%;
  margin-bottom:10%;
  font-family:OpenSans;
  color:black;
}
.inputLabel{
  position:absolute;
  left:5%;
  top:50%;
  z-index: 100;
  transition:border 0.2s ease-in-out, color 0.2s ease-in-out, top 0.2s ease-in-out, transform 0.2s ease-in-out;
  padding-left:2%;
  padding-right:2%;
  padding-bottom:0px;
  font-size:1.3em;
  font-weight: 350;
  width:auto;
  color:#333;
  pointer-events:none;
}
.LoginInput:focus{
  outline:none;
  border:green 1px solid;
}
/* When .LoginInput is focused, set .inputLabel top:0px; */
.LoginInput:focus ~ .inputLabel, .LoginInput:not(:placeholder-shown) ~ .inputLabel, .LoginInput:-webkit-autofill ~ .inputLabel{
  color:white;
  top:-50%;
}
.LoginInput::before{
  content:'Login';
  color:black;
  position:absolute;
  top:0;
  left:0;
}
.LoginButton{
  /* Remove default button stylings */
  background-color:transparent;
  border:none;
  outline:none;
  cursor:pointer;
  /* Remove default link stylings */
  text-decoration:none;
  color:inherit;
  /* Add custom stylings */
  font-size: 30px;
  font-family:inherit;
  width:50%;
  background:#10a37f;
  color:white;
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin:auto;
  padding-top:6px;
  padding-bottom:6px;
  font-size:16px;
  font-weight:350;
  min-height:52px;
  font-kerning: auto;
  font-feature-settings: normal;
  font-family:OpenSans;
  transition:box-shadow 0.2s ease-in-out;
  padding:2%;
  max-width:200px;
  max-height:150px;
}
.LoginButton:hover{
  /* Remove default button hover styling */
  background-color:transparent;
  /* Hover box shadow */
  box-shadow: inset 0px 0px 0px 300px rgba(0,0,0,0.1);
}
.LineBreak{
  border-bottom:#c2c8d0 solid 1px;
  height:2px;
  margin-left:20%;
  margin-right:20%;
  width:60%;
  margin-top: 5%;
  margin-bottom:5%;
}



/* Account Subscription Page */

/* MeteredSubscriptionForm.module.css */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
form{
  padding:7%;
  padding-top:2%;
  box-sizing: border-box;
  overflow-x:hidden;
}

h1 {
  margin-bottom: 1.5rem;
}
form h3{
  font-weight: 200;
}
label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}
textarea{
  width: 100%;
  max-width:90%;
  margin:auto;
  min-height:300px;
}
input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  max-width:90%;
  margin:auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0.25rem;
}
/* input of type checkbox */
input[type="checkbox"] {
  width: auto;
  max-width: none;
  margin: 0.5rem 0;
  background:green;
  width:500px;
}
.tacCheckbox{
  display:inline-block;
  text-align: center;
}
.tacCheckbox h2{
  display:inline-block;
  margin-right:4%;
  margin-bottom:0;
  margin-top:0;
  font-size:1.3em;
  font-weight: 350;
  width:auto;
}

.tacCheckbox input[type="checkbox"]{
  display:inline-block;
  width:auto ;
}

.CardElement {
  padding: 0.5rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0.25rem;
}

button {
  background-color: #0076ff;
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 1rem;
}

.success-message {
  color: green;
  margin-top: 1rem;
}

@media screen and (min-width: 600px) and (orientation: landscape) {
  .LoginModal{
    max-height:400px;
    max-width:400px;
  }
  .MenuActive{
    transform:translateX(-30vw);
  }
  .PopoutMenu{
    min-width:10vw;
    width:25vw !important;
  }
  .PopoutLink:hover{
    background-color:rgba(250,250,250,0.2);
  }
}
