.TokenUsageDataContainer {
    width: 100svw;
    box-sizing: border-box;
    padding: 5%;
    /* next line text if overflow */
    white-space: pre-line;
    overflow-y: overlay;
    overflow-x: hidden;
    text-align: center;
    height: 100svh;
}

table {
    width: 95%;
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
    box-sizing: border-box;
    margin: auto;
    margin-bottom: 5%;
    text-align: left;
    background: #1A2631;
    border-spacing: 30px;
    /* Drop shadow */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

td, th {
    /* border-bottom:1px solid white;
    border-right: 1px solid white; */
    padding: 2%;
    border-bottom: 1px solid #121212;
    font-size: .9em;
    white-space: pre-line;
}

td span, th span {
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 15px;
    color: white;
}
th span{
    font-weight:400;
}

td:nth-of-type(even),th:nth-of-type(even) {
    text-align: right;
}

a {
    text-transform: none;
    color: white;
}
textarea:disabled{
    background:White;
    color:black;
}

.BillingDateTD {
    border-bottom: none !important;
}

.TokenUsageButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    box-sizing: border-box;
}

/* Dashboard */
.Dashboard {
    padding-left: 5%;
    margin-top: 4%;
    padding-right: 10%;
    width: 25%;
}

.DashboardHeader {
    font-size: 50px;
}

.DashboardButton {
    cursor: pointer;
    font-size: 15px;
    width: auto;
    /* Remove default button stylings */
    border: none;
    outline: none;
    cursor: pointer;
    /* Remove default link stylings */
    text-decoration: none;
    color: inherit;
    margin-top: 0;
    font-family: Poppins;
    text-decoration: none;
    padding: 4%;
    border-radius: 1em;
    background: #1A2631;
    color: white;
    font-size: large;
    font-weight: 500;
    transition: box-shadow 200ms ease-in-out, color 200ms ease-in-out;
    margin-bottom: 5%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.DashboardButton:hover{
    background:#1A2631;
}

.CenteredWrapper {
    display: grid;
    height: 100vh;
    width: 100vw;
    place-items: center;
    position: relative;
    background: #121212;
    text-align: center;
    padding: 4%;
    box-sizing: border-box;
}
.CenteredWrapper h3 {
    font-weight: 200;
}

.ctaButtonWrapper {
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom, 20vh) !important;
}

/* Add media queries for desktop mode */
@media screen and (min-width: 1000px) {

    table {
        width: 70%;
    }

    .TokenUsageButtons {
        display: inline-block;
    }

    .DashboardButton {
        display: inline-block;
        width: auto;
        margin: 2%;
        font-weight: 400;
        font-size: 1.2em;
    }

    .DashboardButton:hover {
        box-shadow:
            0 0 25px #fff,
            -10px 0 40px #f0f,
            10px 0 40px #0ff;
    }

}